import React, { Fragment, useEffect } from 'react'

const UserRow = ({ changeDiv, userData, userDetails }) => {

    return (
        <Fragment>
            {
                userData &&
                (<Fragment>
                    <div className={`row chat-row ${userDetails && userDetails._id === userData._id && 'selected-chat'}`} onClick={() => (!userDetails && changeDiv(userData), userDetails && userDetails._id !== userData._id && changeDiv(userData))}>
                        <div className="chat-left">
                            {/* process.env.REACT_APP_API_URL + */}
                            <img src={userData && userData.ProfilePic ? process.env.REACT_APP_API_URL + userData.ProfilePic : "/dist/img/user.png"} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/dist/img/user.png";
                            }} />
                        </div>
                        <div className="chat-middle">
                            <h6 className='chat-name' style={{ position: 'relative', top: '10px' }}>{userData ? userData.firstname + ' ' + userData.name : ''}</h6>

                        </div>
                    </div>
                </Fragment>)
            }
        </Fragment >
    )
}

export default UserRow
