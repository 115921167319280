import React, { Fragment, useEffect, useState } from 'react';
import agent from '../../../api/agent';
import FileInput from '../../core/file-input';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify'
import InputEmoji from 'react-input-emoji'
import { socket } from '../../pages/socket.jsx';
import useWindowDimensions from '../../core/windowDimension';


const ChatBottom = ({ sendText, currentUser, userDetails }) => {
    const [textbox, setTextBox] = useState('');
    const [fileInputKey, setFileInputKey] = useState(0);
    const [file, setFile] = useState(null);
    const [picturePreview, setPicturePreview] = useState(null);
    const [typingUser, setTypingUser] = useState(null);
    const { width, height } = useWindowDimensions();
    const hitEnter = (e) => {
        if (textbox.trim() !== '') {
            if (e.keyCode === 13 && !e.shiftKey) {
                sendText(textbox);
                e.preventDefault();
                setTextBox('');
            }
        } else {
            if (e.keyCode === 13)
                e.preventDefault();
        }
    }
    const { isLoading, mutate } = useMutation((values) =>
        agent.User.AttachmentSend(values), { retry: 2 }
    );
    const onChangeInput = (e) => {
        if (e.currentTarget.files[0].type.slice(0, 5) !== 'image') {
            if (e.currentTarget.files[0].size > 3000000) {
                toast.warning('Maximum file size 3 MB');
                return false;
            }
        }
        toast.warning('Sending,Please wait few moments...', { autoClose: !isLoading });
        setFile(e.currentTarget.files[0]);
        let formData = new FormData();
        formData.append('UserId', currentUser._id);
        formData.append('SelectId', document.getElementById('userId').innerText);
        formData.append('UserType', document.getElementById('userType').innerText);
        formData.append('MsgType', e.currentTarget.files[0].type.slice(0, 5) === 'image' ? 'image' : 'file');
        formData.append('FileName', e.currentTarget.files[0].name);
        formData.append('file', e.currentTarget.files[0]);
        mutate(formData, {
            onSuccess: (data) => {
                if (data === 'Yes Done it') {
                } else
                    toast.error('Failed to send');
            },
            onError: (error) => {
                toast.warning(error.data);
            }
        })
    };
    useEffect(() => {
        setTypingUser(null);
        setTextBox('');
    }, [userDetails])
    useEffect(() => {
        socket.on('isTypingShow' + currentUser._id, (data) => {
            if (data.TypingStatus === true) {
                setTypingUser(data);
                setTimeout(() => {
                    setTypingUser(null);
                }, 5000)
            } else {
                setTypingUser(null);
            }
        });
        return function cleanup() {
            socket.off('isTypingShow' + currentUser._id);
        }
    }, [])
    // useEffect(() => {
    //     if (typingUser !== null) {
    //         if (typingUser.selectedId === userDetails.FriendID) {
    //             document.querySelector('.typing-span').style.display = 'block';
    //         }
    //     } else {
    //         document.querySelector('.typing-span').style.display = 'none';
    //     }
    // }, [typingUser]);
    const handleTypeText = (e) => {
        setTextBox(e);
        socket.emit('isTyping', {
            UserId: currentUser._id,
            TypingUser: currentUser.firstname,
            TypingStatus: e !== '' ? true : false,
            SelectId: document.querySelector('.friendId').innerText,
            UserType: document.querySelector('.userType').innerText,
        })
    }
    return (
        <Fragment>
            <div className="chat-bottom">

                {width > 450 ?
                    <Fragment>
                        <div className="media-div">
                            <div className='gallery-options'>
                                <img src="/dist/img/media.png" alt="send" id='btnMedia' />
                                <div className="file-input">
                                    <FileInput
                                        name={'file'}
                                        accept={'.zip,.rar,.doc,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.txt,.png,.jpg,.jpeg,.gif'}
                                        setPicturePreview={setPicturePreview}
                                        setFile={setFile}
                                        onChangeInput={onChangeInput}
                                        noFormik={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="text-div">
                            {
                                typingUser !== null &&
                                typingUser.selectedId === userDetails.FriendID &&
                                <span className='typing-span'>{typingUser && typingUser.data}</span>

                            }
                            {/* <textarea className='text-box' id='messageBox' onKeyUp={(e) => setTextBox(e.target.value.trim())} onKeyDown={(e) => hitEnter(e)} autoFocus placeholder='Write your message' cols="30" rows="3"></textarea> */}
                            <InputEmoji
                                value={textbox}
                                onChange={handleTypeText}
                                cleanOnEnter
                                id='messageBox'
                                style={{ maxHeight: '60px' }}
                                placeholder="Taper un message"
                                onKeyDown={(e) => hitEnter(e)}
                            />
                            <h6 className='friendId d-none'>{userDetails && userDetails.ContactType === 'Person' ? userDetails.UserInfo[0]._id : userDetails.ContactType === 'Group' ? userDetails.GroupInfo[0]._id : userDetails._id}</h6>
                            <h6 className='userType d-none'>{userDetails && userDetails.ContactType === 'Person' ? userDetails.UserInfo[0].ContactType : userDetails.ContactType}</h6>

                        </div>
                    </Fragment>
                    :
                    <Fragment>
                        <div className="rounded-container">
                            <div className="media-div">
                                <div className='gallery-options'>
                                    <img src="/dist/img/media.png" alt="send" id='btnMedia' />
                                    <div className="file-input">
                                        <FileInput
                                            name={'file'}
                                            accept={'.zip,.rar,.doc,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.txt,.png,.jpg,.jpeg,.gif'}
                                            setPicturePreview={setPicturePreview}
                                            setFile={setFile}
                                            onChangeInput={onChangeInput}
                                            noFormik={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="text-div">
                                {
                                    typingUser !== null &&
                                    typingUser.selectedId === userDetails.FriendID &&
                                    <span className='typing-span'>{typingUser && typingUser.data}</span>
                                }
                                {/* <textarea className='text-box' id='messageBox' onKeyUp={(e) => setTextBox(e.target.value.trim())} onKeyDown={(e) => hitEnter(e)} autoFocus placeholder='Write your message' cols="30" rows="3"></textarea> */}
                                <InputEmoji
                                    value={textbox}
                                    onChange={handleTypeText}
                                    cleanOnEnter
                                    id='messageBox'
                                    style={{ maxHeight: '60px' }}
                                    placeholder="Taper un message"
                                    onKeyDown={(e) => hitEnter(e)}
                                />
                                <h6 className='friendId d-none'>{userDetails && userDetails.ContactType === 'Person' ? userDetails.UserInfo[0]._id : userDetails.ContactType === 'Group' ? userDetails.GroupInfo[0]._id : userDetails._id}</h6>
                                <h6 className='userType d-none'>{userDetails && userDetails.ContactType === 'Person' ? userDetails.UserInfo[0].ContactType : userDetails.ContactType}</h6>

                            </div>
                        </div>
                    </Fragment>
                }
                <div className="send-div">
                    <img src="/dist/img/send.png" alt="send" id='btnSend' onClick={() => { sendText(textbox); setTextBox('') }} />
                </div>
            </div>
        </Fragment >
    );
};

export default ChatBottom;
