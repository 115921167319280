import { Fragment, Suspense, useEffect, useState } from 'react';
import { selectCurrentUser } from './redux/user/user.selector';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import AppRoutes from './app-routes';

const App = ({ currentUser }) => {
  const [windowHeight, setWindowHeight] = useState([window.innerHeight]);
  useEffect(() => {
    window.addEventListener("resize", changeWindowWidth);
  }, []);
  const changeWindowWidth = () => {
    setWindowHeight([window.innerHeight]);
  }
 
  return (
    <Fragment>
      <AppRoutes height={windowHeight} />
    </Fragment >
  );
}
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});
export default connect(mapStateToProps)(App);