import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <Fragment>
            <div className="error-page pt-5 mt-4 text-center">
                <div className="error-content">
                    <h1>
                        <i className="fas fa-exclamation-triangle text-warning" />
                        <span className=""> Oops! 404
                            Page not found.</span>
                    </h1>
                    <p className='mt-4 text-danger'>
                        <img src="/dist/img/not-found.png" alt="not-found" style={{ width: '100%', maxHeight: '500px', objectFit: 'contain' }} />
                    </p>

                </div>
            </div>
        </Fragment>
    );
};

export default NotFound;
