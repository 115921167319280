import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'

const ChatTop = ({ backList, userDetails, handleEndConversation, userModalShow, setUserModalShow }) => {

    return (
        <Fragment>
            {
                userDetails.ContactType === 'User'
                &&
                <Fragment>
                    <div className="chat-top">
                        <div className="img-div d-flex text-left justify-content-center">
                            <span onClick={backList} className='d-flex d-md-none ms-3 mt-3'> <i className="fas fa-chevron-left fa-lg" ></i></span>
                            <img src={userDetails && userDetails.ProfilePic ? process.env.REACT_APP_API_URL + userDetails.ProfilePic : "/dist/img/user.png"} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/dist/img/user.png";
                            }} />
                        </div>
                        <div className="name-div text-left">
                            <h5 className='mt-3' onClick={() => setUserModalShow(!userModalShow)}>{userDetails.firstname + ' ' + userDetails.name}</h5>
                            <h6 id='userId' className='d-none'>{userDetails._id}</h6>
                            <h6 id='userType' className='d-none'>{userDetails.ContactType}</h6>
                        </div>
                    </div>
                </Fragment>
            }
            {
                userDetails.ContactType === 'Person'
                &&
                <Fragment>
                    <div className="chat-top">
                        <div className="img-div d-flex text-left justify-content-center">
                            <span onClick={backList} className='d-flex d-md-none ms-3 mt-3'> <i className="fas fa-chevron-left fa-lg" ></i></span>
                            <img src={userDetails && userDetails.UserInfo[0].ProfilePic ? process.env.REACT_APP_API_URL + userDetails.UserInfo[0].ProfilePic : "/dist/img/user.png"} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/dist/img/user.png";
                            }} />
                        </div>
                        <div className="name-div text-left">
                            <h5 className='mt-3' onClick={() => setUserModalShow(!userModalShow)}>{userDetails.UserInfo[0].firstname + ' ' + userDetails.UserInfo[0].name}</h5>
                            <h6 id='userId' className='d-none'>{userDetails.UserInfo[0]._id}</h6>
                            <h6 id='userType' className='d-none'>{userDetails.UserInfo[0].ContactType}</h6>
                        </div>
                    </div>
                </Fragment>
            }
            {
                userDetails.ContactType === 'Group'
                &&
                <Fragment>
                    <div className="chat-top">
                        <div className="img-div d-flex text-left justify-content-center">
                            <span onClick={backList} className='d-flex d-md-none ms-3 mt-3'> <i className="fas fa-chevron-left fa-lg" ></i></span>
                            <img src={userDetails && userDetails.GroupInfo[0].GroupPic ? process.env.REACT_APP_API_URL + userDetails.GroupInfo[0].GroupPic : "/dist/img/group.png"} onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/dist/img/group.png";
                            }} />
                        </div>
                        <div className="name-div text-left">
                            <h5 className='mt-3' onClick={() => setUserModalShow(!userModalShow)}>{userDetails.GroupInfo[0].GroupName}</h5>
                            <h6 id='userId' className='d-none'>{userDetails.GroupInfo[0]._id}</h6>
                            <h6 id='userType' className='d-none'>{userDetails.ContactType}</h6>
                        </div>
                    </div>
                </Fragment>
            }

        </Fragment>
    )
}
export default ChatTop
