import React, { Fragment, useEffect, useState } from 'react'
import io from 'socket.io-client';
import { selectCurrentUser } from '../../redux/user/user.selector';
import ChatBottom from '../features/chat/chat-bottom';
import ChatMiddle from '../features/chat/chat-middle';
import ChatRow from '../features/chat/chat-row';
import ChatTop from '../features/chat/chat-top';
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { confirmAlert } from 'react-confirm-alert';
import OwnProfileModal from './own-profile-modal';
import UserProfileModal from './user-profile-modal';
import { setCurrentUser } from '../../redux/user/user.action';
import agent from '../../api/agent';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify'
import { Navigate, useNavigate } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { BsChatRight, BsChatSquareText, BsSearch } from 'react-icons/bs'
import { MdGroups } from 'react-icons/md'
import { AiOutlineUsergroupAdd, AiOutlineUser } from 'react-icons/ai'
import Button from '../core/button';
import CreateGroupModal from '../features/group/create-group-modal';
import UserRow from '../features/chat/user-row';
import { socket } from './socket.jsx';

const Chat = ({ currentUser, setCurrentUser }) => {

    let navigate = useNavigate();
    const [width, setWidth] = useState([window.innerWidth]);
    const [height, setHeight] = useState([window.innerHeight]);
    const [userDetails, setUserDetails] = useState(null);
    const [showSearch, setShowSearch] = useState(false)
    const [activeKey, setActiveKey] = useState('part1');
    const [chatRow, setChatRow] = useState([]);
    const [filterChatRow, setFilterChatRow] = useState([]);
    const [groupRow, setGroupRow] = useState([]);
    const [filterGroupRow, setFilterGroupRow] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [filterAllRow, setFilterAllRow] = useState([]);
    const [chat, setChat] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [userModalShow, setUserModalShow] = useState(false);
    const [groupModalShow, setGroupModalShow] = useState(false);
    var selectedUserId = "";

    const { data, refetch } = useQuery(["chat-list", activeKey === 'part3'], () => agent.User.loadAllUser({ UserId: currentUser._id }),
        {
            refetchOnWindowFocus: false,//turned off on window focus refetch option
            enabled: false, // turned off by default, manual refetch is needed
            onSuccess: (d) => {
                setAllUsers(d);
                setFilterAllRow(d);
            }
        }
    );
    const { data: groupData, refetch: groupRefetch } = useQuery(["group-list", activeKey === 'part2'], () => agent.User.loadAllGroup({ UserId: currentUser._id }),
        {
            refetchOnWindowFocus: false,//turned off on window focus refetch option
            enabled: false, // turned off by default, manual refetch is needed
            onSuccess: (d) => {
                setGroupRow(d);
                setFilterGroupRow(d);
            }
        }
    );
    const { data: recentData, refetch: recentRefetch } = useQuery(["recent-list", activeKey === 'part1'], () => agent.User.loadAllRecentChat({ UserId: currentUser._id }),
        {
            refetchOnWindowFocus: false,//turned off on window focus refetch option
            enabled: false, // turned off by default, manual refetch is needed
            onSuccess: (d) => {
                setChatRow(d);
                setFilterChatRow(d);
            }
        }
    );
    useEffect(() => {
        recentRefetch();
    }, [activeKey === 'part1']);
    useEffect(() => {
        groupRefetch();
    }, [activeKey === 'part2']);
    useEffect(() => {
        refetch();
    }, [activeKey === 'part3']);
    var chatData = [];
    useEffect(() => {
        window.addEventListener("resize", changeWindowWidth);
    }, []);

    const changeWindowWidth = () => {
        setWidth([window.innerWidth]);
        setHeight([window.innerHeight]);
    }
    const scrollChatMiddle = () => {
        document.getElementById('chat-middle').scrollTop = document.getElementById('chat-middle').scrollHeight
    }
    const userDataBind = (e) => {
        chatData = [];
        setChat(chatData);
        selectedUserId = e._id;
        socket.emit("loadAllChat", {
            UserId: currentUser._id,
            SelectId: e.ContactType === 'Person' ? e.UserInfo[0]._id : e.ContactType === 'Group' ? e.GroupInfo[0]._id : e._id,
            UserType: e.ContactType === 'Person' ? e.UserInfo[0].ContactType : e.ContactType
        });
        socket.emit('clearNotification', {
            UserId: currentUser._id,
            SelectId: e.ContactType === 'Person' ? e.UserInfo[0]._id : e.ContactType === 'Group' ? e.GroupInfo[0]._id : e._id,
            UserType: e.ContactType === 'Person' ? e.UserInfo[0].ContactType : e.ContactType
        })
        socket.emit('messageSeenByUser', {
            UserId: currentUser._id,
            SelectId: e.ContactType === 'Person' ? e.UserInfo[0]._id : e.ContactType === 'Group' ? e.GroupInfo[0]._id : e._id,
            UserType: e.ContactType === 'Person' ? e.UserInfo[0].ContactType : e.ContactType
        })

    }
    useEffect(() => {
        socket.on("loadData" + currentUser._id, (data) => {
            chatData = [];
            data.forEach(element => {
                chatData.push(element);
            });
            setChat(chatData);
            scrollChatMiddle();
        });
        socket.on("rcvOwnMsg" + currentUser._id, (data) => {
            recentRefetch();
            if (data.ChatType === 'Group') {
                if (data.GroupID === document.getElementById('userId').innerText) {
                    setChat((chat) => [...chat, data]);
                    scrollChatMiddle();
                }
            } else {
                if (data.ReceiverId === document.getElementById('userId').innerText) {
                    setChat((chat) => [...chat, data]);
                    scrollChatMiddle();
                }
            }
        });
        socket.on("sentToRcvr" + currentUser._id, (data) => {
            recentRefetch();
            if (data.ChatType === 'Group') {
                if (data.GroupID === (document.getElementById('userId') && document.getElementById('userId').innerText)) {
                    setChat((chat) => [...chat, data]);
                    scrollChatMiddle();
                }
            } else {
                if (data.SenderID === (document.getElementById('userId') && document.getElementById('userId').innerText)) {
                    setChat((chat) => [...chat, data]);
                    scrollChatMiddle();
                }
            }
        });
        socket.on("memberAlertForGrpAdd" + currentUser._id, (data) => {
            groupRefetch();
        });
        socket.on("clearNotificationSuccess" + currentUser._id, (data) => {
            recentRefetch();
        });
        socket.on("seenStatusChange" + currentUser._id, (data) => {
            recentRefetch();
            document.getElementById(data._id).classList.add('text-primary');
        });
        socket.on("saveConnectionSuccess" + currentUser._id, (data) => {
            recentRefetch();
        });
        return function cleanup() {
            socket.off('loadData' + currentUser._id);
            socket.off('rcvOwnMsg' + currentUser._id);
            socket.off('sentToRcvr' + currentUser._id);
            socket.off('memberAlertForGrpAdd' + currentUser._id);
            socket.off('clearNotificationSuccess' + currentUser._id);
            socket.off('seenStatusChange' + currentUser._id);
            socket.off('saveConnectionSuccess' + currentUser._id);
        }
    }, [])
    useEffect(() => {
        if (width > 768) {
            const leftDiv = document.querySelector('.left-chat-div');
            const rightDiv = document.querySelector('.right-chat-div');
            rightDiv.style.display = 'inline-block';
            leftDiv.style.display = 'inline-block';
        }
    }, [width])
    const changeDiv = (e) => {

        if (width < 768) {
            const leftDiv = document.querySelector('.left-chat-div');
            const rightDiv = document.querySelector('.right-chat-div');
            rightDiv.style.display = 'inline-block';
            leftDiv.style.display = 'none';
        } else {
            const leftDiv = document.querySelector('.left-chat-div');
            const rightDiv = document.querySelector('.right-chat-div');
            rightDiv.style.display = 'inline-block';
            leftDiv.style.display = 'inline-block';
        }
        if (e._id !== '') {
            setUserDetails(e);
            userDataBind(e);
        }
        else { setUserDetails(''); }
    }
    const backList = () => {
        if (width < 768) {
            const leftDiv = document.querySelector('.left-chat-div');
            const rightDiv = document.querySelector('.right-chat-div');
            rightDiv.style.display = 'none';
            leftDiv.style.display = 'inline-block';
        } else {
            const leftDiv = document.querySelector('.left-chat-div');
            const rightDiv = document.querySelector('.right-chat-div');
            rightDiv.style.display = 'inline-block';
            leftDiv.style.display = 'inline-block';
        }
    }
    const sendText = (data) => {
        // document.getElementById('messageBox').value = '';
        if (data !== '') {
            socket.emit("sendMessage", {
                UserId: currentUser._id,
                SelectId: document.getElementById('userId').innerText,
                Message: data,
                MsgType: 'text',
                UserType: document.getElementById('userType').innerText,
                FileName: '',
            });
        }
        scrollChatMiddle();
        document.querySelector('.react-input-emoji--input').focus();
    }
    const handleSearch = (e) => {
        if (activeKey === 'part1') {
            if (e.target.value !== '') {
                setFilterChatRow(
                    chatRow.filter((user) => {
                        return (
                            user.ContactType === 'Person' ?
                                (user.UserInfo[0].firstname + ' ' + user.UserInfo[0].name).toUpperCase().match(e.target.value.toUpperCase())
                                :
                                user.GroupInfo[0].GroupName.toUpperCase().match(e.target.value.toUpperCase())
                        )
                    })
                )
            } else {
                recentRefetch();
            }
        } else if (activeKey === 'part2') {
            if (e.target.value !== '') {
                setFilterGroupRow(
                    groupRow.filter((user) => {
                        return (
                            user.GroupInfo[0].GroupName.toUpperCase().match(e.target.value.toUpperCase())
                        )
                    })
                )

            } else {
                groupRefetch();
            }
        }
        else if (activeKey === 'part3') {
            if (e.target.value !== '') {
                setFilterAllRow(
                    allUsers.filter((user) => {
                        return (
                            (user.firstname + ' ' + user.name).toUpperCase().match(e.target.value.toUpperCase())
                        )
                    })
                )
            } else {
                refetch();
            }
        }

    }
    const handleSignOut = () => {
        setCurrentUser(null);
        navigate('/');
    }
    useEffect(() => {
        document.querySelector('.react-input-emoji--input') && document.querySelector('.react-input-emoji--input').focus()
    }, [userDetails])
    return (
        <Fragment>
            <div className="row m-0 chat-parent">
                <div className="left-chat-div">
                    <div className={`chat-user-div ${!showSearch ? 'mb-4' : ''}`}>
                        <div className="own-info-div">
                            <img src={currentUser && currentUser.ProfilePic ? process.env.REACT_APP_API_URL + currentUser.ProfilePic : "/dist/img/user.png"} alt="own_img" onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = "/dist/img/user.png";
                            }} />
                            <h5 className='own-title' onClick={() => setModalShow(!modalShow)}>
                                {currentUser && currentUser.firstname + ' ' + currentUser.name}
                            </h5>
                            <span className="logout cursor-pointer" onClick={() => setShowSearch(!showSearch)}><BsSearch className='fw-bold' size={20} /></span>
                        </div>
                        {showSearch &&
                            <div className="user-search-div mb-4">
                                <input type="search" className="search-user" placeholder='Rechercher...' onChange={(e) => handleSearch(e)} />
                            </div>}
                    </div>
                    <div className="chat-row-div">
                        <div className="row">
                            <div className="col-12 p-0">
                                <Button style={{ position: 'absolute', right: '0', top: '2px' }} ButtonText={<AiOutlineUsergroupAdd size={20} />} title={'Créer un groupe'} onClick={() => setGroupModalShow(!groupModalShow)} className={'btns-primary btn-create-group'} />
                                <Tabs
                                    activeKey={`${activeKey}`}
                                    transition={false}
                                    id="noanim-tab-example"
                                    className="mb-3"
                                    onSelect={(e) => setActiveKey(e)}
                                >
                                    <Tab eventKey='part1' title={<span><BsChatSquareText size={16} style={{ position: 'relative', top: '-2px' }} />&nbsp;&nbsp;Récent</span>}>
                                        {
                                            filterChatRow && filterChatRow.length > 0 ?
                                                (
                                                    filterChatRow.map((user, i) => (
                                                        user &&
                                                        <ChatRow changeDiv={changeDiv} currentUser={currentUser} userData={user} key={i} userDetails={userDetails} />
                                                    ))
                                                ) :
                                                <div className="row">
                                                    <div className="col-12 text-center text-dark mt-5 pt-5">
                                                        <h6>Aucune conversation trouvée</h6>
                                                    </div>
                                                </div>

                                        }
                                    </Tab>
                                    <Tab eventKey='part2' title={<span><MdGroups size={20} style={{ position: 'relative', top: '-2px' }} />&nbsp;&nbsp;Groupes</span>}>
                                        {
                                            filterGroupRow && filterGroupRow.length > 0 ?
                                                (
                                                    filterGroupRow.map((user, i) => (
                                                        user &&
                                                        <ChatRow changeDiv={changeDiv} userData={user} key={i} userDetails={userDetails} />
                                                    ))
                                                )
                                                :
                                                <div className="row">
                                                    <div className="col-12 text-center text-dark mt-5 pt-5">
                                                        <h6>Aucun groupe trouvé</h6>
                                                    </div>
                                                </div>

                                        }
                                    </Tab>
                                    <Tab eventKey='part3' title={<span><AiOutlineUser size={16} style={{ position: 'relative', top: '-2px' }} />&nbsp;&nbsp;Contacts</span>}>
                                        {
                                            filterAllRow && filterAllRow.length > 0 ?
                                                (
                                                    filterAllRow.map((user, i) => (
                                                        user &&
                                                        <UserRow changeDiv={changeDiv} userData={user} key={i} userDetails={userDetails} />
                                                    ))
                                                ) :
                                                <div className="row">
                                                    <div className="col-12 text-center text-dark mt-5 pt-5">
                                                        <h6>No User Found</h6>
                                                    </div>
                                                </div>

                                        }
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="right-chat-div" style={{ height: height + 'px' }}>

                    <Fragment>
                        {userDetails &&
                            <ChatTop backList={backList} userDetails={userDetails} setUserModalShow={setUserModalShow} userModalShow={userModalShow} />
                        }
                        {userDetails &&
                            <div id='chat-middle' className="chat-middle">
                                {chat.length > 0 ?
                                    <ChatMiddle message={chat} socket={socket} scrollChatMiddle={scrollChatMiddle} currentUser={currentUser} />
                                    :
                                    <div className="col-12 text-center">
                                        <div className="spinner-grow" style={{ width: '3rem', height: '3rem', position: 'relative', marginTop: 'calc(100% - 85%)', marginLeft: 'calc(100% - 100%)' }} role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>

                                    </div>

                                }
                            </div>
                        }
                        {userDetails &&
                            <ChatBottom sendText={sendText} currentUser={currentUser} userDetails={userDetails} />
                        }
                    </Fragment>
                    {
                        !userDetails &&
                        <Fragment>
                            <div className="col-12 mt-md-5 pt-2 pt-md-5 text-center justify-content-center">
                                <div className="row">
                                    <div className="col-12 text-center mt-5 pt-5">
                                        {/* <img src="/dist/img/chat-begin.png" style={{ width: '175px' }} alt="chat-with-someone" /> */}
                                    </div>
                                </div>
                                <div className="col-12 text-center">
                                    <h3 className='mt-4'>Bienvenue sur la messagerie instantanée</h3>
                                </div>
                            </div>
                        </Fragment>
                    }
                </div >
            </div>
            <OwnProfileModal
                modalShow={modalShow}
                setModalShow={setModalShow}
            />
            <UserProfileModal
                modalShow={userModalShow}
                setModalShow={setUserModalShow}
                userData={userDetails}
                recentRefetch={recentRefetch}
                currentUser={currentUser}
            />
            <CreateGroupModal
                modalShow={groupModalShow}
                setModalShow={setGroupModalShow}
                currentUser={currentUser}
                recentRefetch={recentRefetch}
            />
        </Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});
const mapDispatchToProps = (dispatch) => ({
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Chat);