import { Modal } from 'react-bootstrap';
import React, { Fragment, useEffect, useState } from 'react'
import TextField from '../../core/text-field';
import FileInput from '../../core/file-input';
import { MdAddPhotoAlternate } from 'react-icons/md'
import Button from '../../core/button';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import agent from '../../../api/agent';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

const CreateGroupModal = ({
    modalShow,
    setModalShow,
    currentUser,
    recentRefetch
}) => {
    const [picturePreview, setPicturePreview] = useState(null);
    const [fileInputKey, setFileInputKey] = useState(0);
    const [file, setFile] = useState(null);
    const [arrayData, setArrayData] = useState([]);

    const { isLoading, mutate } = useMutation((values) =>
        agent.User.createGroup(values), { retry: 2 }
    );

    const { data: UserData, refetch, isLoading: userLoading } = useQuery(["chat-list", modalShow === true], () => agent.User.loadAllUser({ UserId: currentUser._id }),
        {
            refetchOnWindowFocus: false,//turned off on window focus refetch option
            enabled: false, // turned off by default, manual refetch is needed
            onSuccess: (d) => {
            }
        }
    );
    useEffect(() => {
        refetch();
    }, [modalShow === true]);

    const modalClick = (e) => {
        if (e.target.className === "fade modal show") {
            e.preventDefault();
        }
    };

    const onChangeImageInput = (e) => {
        setFile(e.currentTarget.files[0]);
        formik.setFieldValue('file', e.currentTarget.files[0])
    };
    const handleArray = (e, rowData) => {
        if (e.target.checked) {
            setArrayData((arrayData) => [...arrayData, rowData._id]);
        } else {
            setArrayData((oldData) => {
                return oldData.filter(id => id !== rowData._id);
            });
        }
    }
    const handleCheckAll = (e) => {
        const checkedbox = document.getElementsByName('rowCheckBox');
        for (let index = 0; index < checkedbox.length; index++) {
            checkedbox[index].checked = e.target.checked;

        }
        if (e.target.checked) {
            setArrayData([]);
            UserData && UserData.map((i) => (
                setArrayData((arrayData) => [...arrayData, i._id])
            ));
        } else {
            setArrayData([]);
        }
    }

    const formik = useFormik({
        initialValues: {
            GroupName: '',
            UserList: [],
            file: ''
        },
        validationSchema: Yup.object({
            GroupName: Yup.mixed().required(),
            UserList: Yup.mixed().required(),
        }),
        validateOnChange: true,
        validateOnBlur: false,
        onSubmit: (values, { resetForm }) => {
            values.UserList.push(currentUser._id);
            let formData = new FormData();
            formData.append('GroupName', values.GroupName);
            formData.append('UserList', JSON.stringify(values.UserList));
            formData.append('Creator', currentUser._id);
            formData.append('file', values.file);
            mutate(formData, {
                onSuccess: (data) => {
                    if (data === 'success') {
                        resetForm();
                        setModalShow(!modalShow);
                        recentRefetch();
                        toast.success('Group Created Successfully');
                    } else {
                        toast.warning('Failed to create');
                    }
                },
                onError: (error) => {
                    toast.warning(error);
                }
            })
        }
    })
    useEffect(() => {
        formik.setFieldValue('UserList', arrayData);
    }, [arrayData])
    useEffect(() => {
        formik.resetForm();
        setPicturePreview(null);
        setArrayData([]);
        if (UserData !== null) {
            const checkedbox = document.getElementsByName('rowCheckBox');
            for (let index = 0; index < checkedbox.length; index++) {
                checkedbox[index].checked = false;
            }
        }
    }, [modalShow])
    function handleChooseImage(e) {
        e.preventDefault();
        var input = document.createElement("input");
        input.type = "file";
        input.multiple = false;
        input.accept = '.png,.jpg,.jpeg';
        input.click();
        input.onchange = (e) => {
            var file = e.currentTarget.files[0]
            if (file.type === "" || file.type.includes('application')) {
                toast.error('Only image accepted')
                return false;
            }
            if (file.type !== "" || file.type.includes('png') || file.type.includes('jpg') || file.type.includes('jpeg')) {
                setFile(file);
                setPicturePreview(URL.createObjectURL(e.currentTarget.files[0]))
                formik.setFieldValue('file', file);
            } else {
                toast.error('Invalid Image Format')
                return false;
            }

        }
    }
    const imgCss = {
        width: '125px',
        height: '125px',
        borderRadius: '50%',
        position: 'relative',
        left: '20px'
    }
    return (
        <Fragment>
            <div onClick={(e) => modalClick(e)}>
                <Modal show={modalShow} size="md" style={{ background: ' rgba(0, 0, 0, 0.7)' }} centered>
                    <Modal.Header className="p-0 border-0">
                        <div className="row w-100">
                            <div className="col-6 pt-2 ps-4">
                                <h5>Créer un groupe</h5>
                            </div>
                            <div className="col-6 p-2 pe-3 text-right">
                                <span className="cursor-pointer" onClick={(e) => { setModalShow(!modalShow); }}><i className="fas fa-times"></i></span>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="p-0">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row mb-4">
                                <div className="col-12 text-center mt-4">
                                    {
                                        picturePreview ?
                                            <img src={picturePreview} style={imgCss} alt="group_img" />
                                            :
                                            <img src={"/dist/img/group.png"} style={imgCss} alt="own_img" />

                                    }
                                    <a className="btn btn-primary mt-2" onClick={(e) => handleChooseImage(e)}
                                        style={{ borderRadius: '50%', padding: '5px', position: 'relative', top: '20px', left: '-5px' }}>
                                        <MdAddPhotoAlternate size={24} className={'ico-postion'} />
                                    </a>
                                </div>
                                <div className="col-12 mt-4 mb-4">
                                    <TextField
                                        name={'GroupName'}
                                        label={'Nom du groupe'}
                                        placeholder={'Enter Group Name'}
                                        {...formik.getFieldProps('GroupName')}
                                    />
                                </div>
                                {
                                    userLoading &&
                                    <div className="col-12 text-center">
                                        <span
                                            className="spinner-border spinner-border-sm me-3"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    </div>
                                }
                                {
                                    UserData &&
                                    <label className='cursor-pointer'>
                                        <input type='checkbox' name="rootChecker" id='rootChecker' onChange={(e) => handleCheckAll(e)} /> Tout sélectionner</label>
                                }
                                <div className="col-12 user-list-div">

                                    <table className="table table-striped table-borderless">
                                        <tbody>
                                            {
                                                UserData && UserData.map((item, key) => (
                                                    <tr key={key}>
                                                        <td style={{ paddingLeft: '10px' }}>
                                                            <div className="d-flex">
                                                                <label style={{
                                                                    width: '100%',
                                                                    margin: '0px'
                                                                }}>
                                                                    <input type="checkbox" name='rowCheckBox' id={item._id} onChange={(e) => handleArray(e, item)} className={'multi-check'} />
                                                                    <img src={item && item.ProfilePic ? process.env.REACT_APP_API_URL + item.ProfilePic : "/dist/img/user.png"} alt="user-pic" className='list-user-pic' onError={({ currentTarget }) => {
                                                                        currentTarget.onerror = null; // prevents looping
                                                                        currentTarget.src = "/dist/img/user.png";
                                                                    }} /><span className="list-user-name wrap-text">{item.firstname + ' ' + item.name}</span>
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 mt-4 text-right">
                                    <Button title={''}
                                        disabled={!(formik.isValid && formik.dirty)}
                                        ButtonText={'Créer le groupe'} type={'submit'} className={'btns-primary'} />
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>
        </Fragment >
    )
}

export default CreateGroupModal