import React, { Fragment, Suspense } from 'react'
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import Chat from './components/pages/chat';
import NotFound from './components/pages/not-found';
import PreLoader from './components/pages/pre-loader';
import UserVerifier from './components/pages/user-verifier';
import { selectCurrentUser } from './redux/user/user.selector';

const AppRoutes = ({ currentUser, height }) => {

    return (
        <Fragment>
            <Suspense fallback={<PreLoader />}><Routes>
                {
                    <Fragment>
                        <Route path=""
                            element={<Navigate to="/verify" replace />} />
                        <Route exact path='/verify/:id' element={<UserVerifier height={height} />}></Route>
                        <Route exact path='/chat' element={<Chat />}></Route>
                        <Route path='*' element={<NotFound />} />
                    </Fragment>

                }
            </Routes>
            </Suspense>
        </Fragment>
    )
}
const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});
export default connect(mapStateToProps, null)(AppRoutes)