import React, { Fragment } from 'react'
import { useState } from 'react';
import { BsCheck2All } from 'react-icons/bs';
import ImgPrevModal from './img-prev-moda';

const ChatRightImage = ({ message, IsWhatsapp, currentUser }) => {
    const [modalShow, setModalShow] = useState(false)
    async function toDataURL(url) {
        const blob = await fetch(url).then(res => res.blob());
        return URL.createObjectURL(blob);
    }
    async function downloadImg(message) {
        var el = document.createElement("a");
        el.setAttribute("href", await toDataURL(process.env.REACT_APP_API_URL + message.Message.slice(3)));
        el.setAttribute("download", message.FileName);

        document.body.appendChild(el);
        el.click();
        el.remove();
    }

    const handleCheckDate = (dateTime) => {
        var d = new Date();
        var today = ("0" + d.getDate()).slice(-2) +
            "/" +
            ("0" + (d.getMonth() + 1)).slice(-2) +
            "/" +
            d.getFullYear()

        var getDate = dateTime.slice(0, 10);
        if (today === getDate) {
            return dateTime.slice(11);
        } else {
            if (d.getFullYear().toString() === dateTime.slice(6, 10)) {
                return dateTime.slice(0, 5) + dateTime.slice(10);
            } else {
                return dateTime;
            }
        }
    }
    return (
        <Fragment>
            <div className="right-chat">
                <div className="right-msg">
                    <div className="col-12 text-right mt-1 pe-0">
                        <span onClick={() => setModalShow(!modalShow)}>
                            <img src={process.env.REACT_APP_API_URL + message.Message.slice(3)} alt="left-img" style={{
                                width: '175px', height: '175px', borderRadius: '20px 20px 0px 20px', background: 'gainsboro',
                                border: '2px solid var(--whatsapp)'
                            }} />
                        </span>
                    </div>
                    <div className="col-12 text-right date-right pe-0" style={{ position: 'relative', top: '10px', fontSize: '13px' }}>
                        <span>{handleCheckDate(message.DateTime)}</span>
                        {
                            (message.SeenStatus === "Unseen") ?
                                <BsCheck2All className='ms-1' /> :
                                (message.SeenStatus !== "Unseen")
                                &&
                                <BsCheck2All className='text-primary ms-1' />
                        }
                    </div>
                </div>
                <div className="chat-img">
                    <img src={currentUser && currentUser.ProfilePic ? process.env.REACT_APP_API_URL + currentUser.ProfilePic : "/dist/img/user.png"} onError={i => i.target.style.display = 'none'} />
                </div>
            </div>
            <ImgPrevModal
                modalShow={modalShow}
                setModalShow={setModalShow}
                message={message}
            />
        </Fragment>
    )
}

export default ChatRightImage
