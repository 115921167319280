import React, { Fragment } from 'react'
import { BsCheck2All } from 'react-icons/bs';
import Linkify from 'react-linkify';
import LinkPreview from '../../core/link-preview';

const ChatLeft = ({ message }) => {
    const handleCheckDate = (dateTime) => {
        var d = new Date();
        var today = ("0" + d.getDate()).slice(-2) +
            "/" +
            ("0" + (d.getMonth() + 1)).slice(-2) +
            "/" +
            d.getFullYear()

        var getDate = dateTime.slice(0, 10);
        if (today === getDate) {
            return dateTime.slice(11);
        } else {
            if (d.getFullYear().toString() === dateTime.slice(6, 10)) {
                return dateTime.slice(0, 5) + dateTime.slice(10);
            } else {
                return dateTime;
            }
        }
    }
    const getUrl = (url) => {
        var urlRE = new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?([^ ])+");
        return url.match(urlRE);
    }
    return (
        <Fragment>
            <div className="left-chat">
                <div className="chat-img">
                    <img src={message.SenderInfo && message.SenderInfo.length > 0 ? message.SenderInfo[0].ProfilePic ? process.env.REACT_APP_API_URL + message.SenderInfo[0].ProfilePic : "/dist/img/user.png" : "/dist/img/user.png"} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/dist/img/user.png";
                    }} />
                </div>
                <div className="left-msg">
                    <div className="col-12 ps-0 text-left">
                        {
                            message.UserName
                        }
                    </div>
                    <div className="left-chat-pointer"></div>
                    <div className="chat shadow">
                        <Linkify
                            componentDecorator={(decoratedHref, decoratedText, key) => (
                                <Fragment>
                                    {
                                        getUrl(decoratedHref) !== null &&
                                        <LinkPreview
                                            className='left-side'
                                            url={decoratedHref}
                                        />
                                    }

                                    <a href={decoratedHref} className={''} key={key} target="_blank">
                                        {decoratedText}
                                    </a>
                                </Fragment>
                            )}>{message.Message}</Linkify></div>
                    <div className="col-12 text-left" style={{ position: 'relative', top: '-10px', fontSize: '13px' }}>
                        <span>{handleCheckDate(message.DateTime)}</span>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ChatLeft;
